import { Injectable } from "@angular/core";
import { GlobalsRepository } from "../repository/globals.repository";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class GlobalsService {

    constructor(private globalsRepo: GlobalsRepository) { }

    public getMaxVolunteerCount(): Observable<number> {
        return this.globalsRepo.getMaxVolunteerCount();
    }

    public setMaxVolunteerCount(count: string): Observable<number> {
        return this.globalsRepo.setMaxVolunteerCount(count);
    }
}