import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

const BASE_URL: string = `${environment.apiUrl}/globals`;

@Injectable({ providedIn: 'root' })
export class GlobalsRepository {
    constructor(private http: HttpClient) { }

    public getMaxVolunteerCount(): Observable<number> {
        return this.http.get<number>(`${BASE_URL}/max-volunteers`)
    }

    public setMaxVolunteerCount(count: string): Observable<number> {
        return this.http.post<number>(`${BASE_URL}/max-volunteers`, count)
    }
}

